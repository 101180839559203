




























































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      ruleMinLength: (value) =>
        !value || value.length >= 6 || "Min 6 characters",
      data: {},
    };
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        const ok = await coreApiClient.callRaw(
          "auth",
          "updatePassword",
          undefined,
          {
            oldPassword: this.data.oldPassword,
            newPassword: this.data.newPassword,
          }
        );
        if (ok) {
          alert("Password changed successfully");
          location.reload();
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
});
